/* eslint-disable no-unused-vars */
import {
  Document,
  Link,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
  pdf,
  PDFDownloadLink,
  renderToFile,
  usePDF,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import React, { useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { saveAs } from "file-saver";
import {
  GetAllActiveCountry,
  GetCurrency,
  GetUserManagementById,
  GetUserNameByAuthId,
} from "../../../axios";
import { TermsAndCondition } from "./TermsandCondition";
import Logo from "../../../assets/icons/furninh logo.png";

const Download = () => {
  <div>
    <PDFDownloadLink fileName="somename.pdf">
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : "Download now!"
      }
    </PDFDownloadLink>
  </div>;
};

const PurchaseOrderReport = (props) => {
  const { poData } = props;
  const [countryDropDown, setCountryDropDown] = React.useState([]);
  const [countryName, setCountryName] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  console.log({ poData });

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      paddingBottom: "40px", // Increased padding at bottom
    },
    tcpage: {
      paddingTop: 40,
      paddingBottom: 80,
      paddingHorizontal: 40,
      fontSize: 10,
      lineHeight: 1.5,
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "200px",
      height: "50px",
    },
    section: {
      margin: 10,
      padding: 10,
      alignItems: "flex-end",
      borderBottom: "1px solid #afafaf",
      marginRight: "25px",
      marginLeft: "25px",
    },
    textLine: {
      fontSize: "8px",
      paddingBottom: "3px",
    },
    textLineBold: {
      fontSize: "8px",
      fontWeight: "900",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      right: "-500px",
      fontSize: "8px",
    },
    textSummery: {
      fontSize: "8px",
      marginTop: "5px",
      textAlign: "justify",
      color: "#595959",
    },
    textSummeryBold: {
      fontSize: "10px",
      marginTop: "5px",
      marginBottom: "5px",
      textAlign: "center",
      fontWeight: "900",
    },
    textSummeryBold0: {
      fontSize: "10px",
      marginTop: "5px",
      marginBottom: "5px",
      textAlign: "center",
      fontWeight: "900",
      textDecoration: "underline",
    },
    textSummeryBold1: {
      fontSize: "10px",
      marginTop: "5px",
      marginBottom: "5px",
      textAlign: "center",
      fontWeight: "900",
    },
    pageNumbers: {
      marginLeft: "40px",
      marginRight: "40px",
      position: "absolute",
      bottom: 5,
      left: 0,
      right: 0,
      textAlign: "center",
      fontSize: "8px",
      paddingTop: "20px",
      paddingBottom: "20px",
      color: "#c5c5c5",
      borderTop: "1px solid #c5c5c5",
    },
    poNumbers: {
      position: "absolute",
      bottom: 5,
      left: 0,
      fontSize: "8",
      paddingTop: "20px",
      color: "#595959",
      marginTop: "10px",
      marginLeft: "25px",
    },
    tcfooter: {
      position: "absolute",
      bottom: 10,
      left: 40,
      right: 40,
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: 10,
      color: "#595959",
    },
    addressContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "space-between",
      border: "1px solid #afafaf",
      marginTop: 20,
      minHeight: 120,
    },
    addressColumn: {
      flex: 1,
      borderRight: "1px solid #afafaf",
    },
    addressColumnLast: {
      flex: 1,
      padding: 8,
    },
    addressHeader: {
      fontSize: 10,
      fontWeight: "bold",
      color: "#474747",
      borderBottom: "1px solid #afafaf",
      paddingBottom: 5,
      marginBottom: 5,
    },
    addressTitle: {
      fontSize: 10,
      fontWeight: "bold",
      marginBottom: 5,
    },
    addressText: {
      fontSize: 8,
      lineHeight: 1.5,
    },
    addressTable: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      border: "1px solid #afafaf",
      borderRadius: "5px",
    },
    tableRow: {
      flexDirection: "row",
      borderBottom: "1px solid #afafaf",
    },
    tableCell: {
      flex: 1,
      borderRight: "1px solid #afafaf",
    },
    tableCellLast: {
      flex: 1,
    },
    tableHeader: {
      backgroundColor: "rgb(188, 204, 204)",
      paddingVertical: 4,
      paddingHorizontal: 8,
      fontWeight: "bold",
      fontSize: 8,
      color: "black",
      borderBottom: "1px solid #afafaf",
    },

    borderLeft: {
      borderTopLeftRadius: "5px",
    },

    borderRight: {
      borderTopRightRadius: "5px",
    },

    tableContent: {
      padding: 8,
      fontSize: 8,
    },
    ribbon: {
      position: "absolute",
      top: "45px",
      left: "-35px",
      width: "200px",
      transform: "rotate(-45deg)",
      padding: "8px",
      textAlign: "center",
      fontSize: "10px",
      color: "white",
      fontWeight: "bold",
      zIndex: 1000,
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    },
    ribbonContainer: {
      position: "absolute",
      top: "-15px",
      left: "-15px",
      width: "150px", // Increased from 100px
      height: "150px", // Increased from 100px
      overflow: "hidden",
      zIndex: 999,
    },
    ribbonCancelled: {
      backgroundColor: "#e74c3c",
    },
    ribbonPending: {
      backgroundColor: "#f1c40f",
    },
    ribbonApproved: {
      backgroundColor: "#2ecc71",
    },
    ribbonRejected: {
      backgroundColor: "#c0392b",
    },
    ribbonDraft: {
      backgroundColor: "#95a5a6",
    },
    ribbonRevision: {
      backgroundColor: "#3498db",
    },
  });

  function calculateAdvancePaymentPercentage(data) {
    if (data.length === 0) {
      return 0;
    }

    const filteredItems = data.filter(
      (item) => item.purchase_order_payment_term_id === 1
    );
    if (filteredItems.length === 0) {
      return 0;
    }
    // const totalValue = poData.PurchaseOrderPaymentTermDetails.reduce(
    //   (sum, item) => sum + parseInt(item.value),
    //   0
    // );

    // const totalPercentage = (filteredItems[0]?.value / totalValue) * 100;
    if (filteredItems[0]?.value == "" || filteredItems[0]?.value == "0") {
      return 0;
    }

    return `${filteredItems[0]?.value} ${
      filteredItems[0]?.option_one ? filteredItems[0]?.option_one : ""
    } ${filteredItems[0]?.option_two ? filteredItems[0]?.option_two : ""}`;
  }
  function calculateProgressPaymentPercentage(data) {
    if (data.length === 0) {
      return 0;
    }
    const filteredItems = data?.filter(
      (item) => item.purchase_order_payment_term_id === 3
    );
    if (filteredItems.length === 0) {
      return "0";
    }

    // Sum up the 'value' for filtered items
    // const totalValue = poData.PurchaseOrderPaymentTermDetails.reduce(
    //   (sum, item) => sum + parseInt(item.value),
    //   0
    // );

    // Calculate total percentage
    // const totalPercentage = (filteredItems[0]?.value / totalValue) * 100;

    if (filteredItems[0]?.value == "" || filteredItems[0]?.value == "0") {
      return 0;
    }
    return `${filteredItems[0]?.value} ${
      filteredItems[0]?.option_one ? filteredItems[0]?.option_one : ""
    } ${filteredItems[0]?.option_two ? filteredItems[0]?.option_two : ""}`;
  }

  function calculateFinalPaymentPercentage(data) {
    if (data.length === 0) {
      return 0;
    }
    const filteredItems = data?.filter(
      (item) => item.purchase_order_payment_term_id === 4
    );
    // console.log(filteredItems);
    if (filteredItems.length === 0) {
      return "0";
    }
    // Sum up the 'value' for filtered items
    // const totalValue = poData.PurchaseOrderPaymentTermDetails.reduce(
    //   (sum, item) => sum + parseInt(item.value),
    //   0
    // );
    // console.log(totalValue);
    // Calculate total percentage
    // const totalPercentage =
    //   (parseInt(filteredItems[0]?.value) / parseInt(totalValue)) * 100;
    // console.log(totalPercentage);
    if (filteredItems[0]?.value == "" || filteredItems[0]?.value == "0") {
      return 0;
    }
    return `${filteredItems[0]?.value} ${
      filteredItems[0]?.option_one ? filteredItems[0]?.option_one : ""
    } ${filteredItems[0]?.option_two ? filteredItems[0]?.option_two : ""}`;
  }

  function getCurrencyName(poData) {
    if (poData != undefined) {
      const value = poData?.item_details.filter((val) => {
        if (val.budgetitem?.budgetquantitydetails !== null) {
          return `${val.budgetitem?.budgetquantitydetails?.actual_currency?.name}`;
        }
      });
      return value[0].budgetitem?.budgetquantitydetails?.actual_currency?.code;
    }
  }
  const currencyName = getCurrencyName(poData);

  // const getAllActiveCountry = () => {
  //   setIsLoading(true);
  //   GetAllActiveCountry()
  //     .then((res) => {
  //       const data = res.data.data;
  //       const result = data.map((ele) => ({
  //         key: ele.id,
  //         value: ele.id,
  //         label: ele.name,
  //       }));
  //       setCountryDropDown(result);

  //       const matchedCountry = result.find(
  //         (country) => country.value === poData?.origin_type_id
  //       );
  //       if (matchedCountry) {
  //         setCountryName(matchedCountry.label);
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //       setIsLoading(false);
  //     });
  // };

  const checkPOItemcode = (data) => {
    let flag = false;
    if (data != null) {
      data.map((ele, index) => {
        if (ele.budgetitem.item_code == null) {
          flag = false;
        } else {
          flag = true;
        }
      });
      return flag;
    }
  };

  const getRibbonStyle = (status) => {
    switch (status) {
      case "Draft":
        return styles.ribbonDraft;
      case "Revision":
        return styles.ribbonRevision;
      case "Signed By Client":
        return styles.ribbonApproved;
      case "Send To Client":
        return styles.ribbonPending;
      case "Rejected By Client":
        return styles.ribbonRejected;
      default:
        return styles.ribbonDraft;
    }
  };

  const getRibbonText = (status) => {
    switch (status) {
      case "Draft":
        return "DRAFT";
      case "Revision":
        return "REVISED";
      case "Signed By Client":
        return "SIGNED";
      case "Send To Client":
        return "SUBMITTED";
      case "Rejected By Client":
        return "REJECTED";
      default:
        return "";
    }
  };

  const [username, setUsername] = React.useState("");

  React.useEffect(() => {
    const fetchUsername = async () => {
      if (poData?.created_by) {
        try {
          const response = await GetUserManagementById(poData.created_by);
          if (response.data.data.name.toLowerCase() !== "admin") {
            setUsername(response.data.data.name);
          }
        } catch (error) {
          console.error("Error fetching username:", error);
        }
      }
    };

    fetchUsername();
  }, [poData]);

  // console.log({ username });

  const shouldShowRibbon = (projectName) => {
    const excludedProjects = [
      "hc 2 - intercontinental hotel red sea",
      "hc3 sls accor",
      "hw1-rosewood red sea",
      "rak mangrove hotel",
    ];
    return !excludedProjects.includes(projectName.toLowerCase());
  };

  return (
    <PDFViewer style={styles.viewer}>
      <Document title={`${poData.purchase_order_serial_number}`}>
        <Page size="A4" style={{ padding: "40px", paddingBottom: "60px" }} wrap>
          {/* Add ribbon */}

          {shouldShowRibbon(
            poData.item_details[0].budgetitem.budget.project.name
          ) && (
            <View style={styles.ribbonContainer}>
              <View
                style={[
                  styles.ribbon,
                  getRibbonStyle(poData.purchase_order_status),
                ]}
              >
                <Text>{getRibbonText(poData.purchase_order_status)}</Text>
              </View>
            </View>
          )}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View></View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Text style={{ fontSize: "24px" }}>
                {" "}
                {poData.purchase_order_status == "Cancelled"
                  ? "CANCELLED PURCHASE ORDER"
                  : "PURCHASE ORDER"}
              </Text>
              <Text style={{ fontSize: "10px" }}>
                {" "}
                #{" "}
                {poData.purchase_order_serial_number
                  ? poData.purchase_order_serial_number
                  : "N/A"}{" "}
              </Text>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginBottom: "20px",
            }}
          >
            {/* project name */}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                marginTop: "10px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  columnGap: "5px",
                  marginRight: "5px",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    color: "black",
                    marginBottom: "5px",
                  }}
                >
                  Project Name
                </Text>
                <Text
                  style={{
                    fontSize: "10px",
                    color: "black",
                    marginBottom: "5px",
                  }}
                >
                  Budget Number
                </Text>
                <Text
                  style={{
                    fontSize: "10px",
                    color: "black",
                    marginBottom: "5px",
                  }}
                >
                  Order Currency
                </Text>
                <Text
                  style={{
                    fontSize: "10px",
                    color: "black",
                    marginBottom: "5px",
                  }}
                >
                  Total ({currencyName})
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  columnGap: "5px",
                  marginLeft: "5px",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    color: "#595959",
                    marginBottom: "5px",
                  }}
                >
                  : {poData.item_details[0].budgetitem.budget.project.name}
                </Text>
                <Text
                  style={{
                    fontSize: "10px",
                    color: "#595959",
                    marginBottom: "5px",
                  }}
                >
                  : {poData.item_details[0].budgetitem.budget.serial_number}
                </Text>
                <Text
                  style={{
                    fontSize: "10px",
                    color: "#595959",
                    marginBottom: "5px",
                  }}
                >
                  : {currencyName}
                </Text>
                <CurrencyFormat
                  value={
                    poData.item_details &&
                    poData.item_details
                      .reduce((acc, curr) => {
                        return acc + parseFloat(curr.total_price);
                      }, 0)
                      .toFixed(2)
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => (
                    <Text
                      style={{
                        fontSize: "10px",
                        color: "#595959",
                        marginBottom: "5px",
                      }}
                    >
                      : {value}
                    </Text>
                  )}
                />
              </View>
            </View>
            {/* dates */}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                marginTop: "10px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  columnGap: "5px",
                  marginRight: "5px",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    color: "black",
                    marginBottom: "5px",
                  }}
                >
                  Issuing Date
                </Text>
                {/* <Text
                  style={{
                    fontSize: "10px",
                    color: "black",
                    marginBottom: "5px",
                  }}
                >
                  Revised Date
                </Text> */}
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  columnGap: "5px",
                  marginLeft: "5px",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    color: "#595959",
                    marginBottom: "5px",
                  }}
                >
                  : {moment(poData.created_on).format("Do MMM YYYY")}
                </Text>
                {/* <Text
                  style={{
                    fontSize: "10px",
                    color: "#595959",
                    marginBottom: "5px",
                  }}
                >
                  : 
                   {moment(poData.updated_on).format("Do MMM YYYY")}
                </Text> */}
              </View>
            </View>
          </View>

          {/* address section */}
          <View style={styles.addressTable}>
            {/* Column 1 */}
            <View style={styles.tableCell}>
              <Text style={[styles.tableHeader, styles.borderLeft]}>
                Vendor Address
              </Text>
              <Text style={styles.tableContent}>
                <Text>
                  {`${poData.vendor.name}
                  Fax: ${poData.vendor?.fax != null ? poData.vendor.fax : ""}
                     Phone: ${
                       poData.vendor?.phone != null ? poData.vendor.phone : ""
                     } 
                     City: ${
                       poData.vendor?.city != null ? poData.vendor.city : ""
                     },${
                    poData.vendor?.postcode != null
                      ? poData.vendor.postcode
                      : ""
                  },${poData.vendor?.state != null ? poData.vendor.state : ""}
                     Address: ${
                       poData.vendor.address ? poData.vendor.address : ""
                     }
                     `}
                </Text>
              </Text>
            </View>

            {/* Column 2 */}
            <View style={styles.tableCell}>
              <Text style={styles.tableHeader}>Shipping Address</Text>
              <Text style={styles.tableContent}>
                {poData.shipping_address ? poData.shipping_address : "N/A"}
              </Text>
            </View>

            {/* Column 3 */}
            <View style={styles.tableCellLast}>
              <Text style={[styles.tableHeader, styles.borderRight]}>
                Billing Address
              </Text>
              <Text style={styles.tableContent}>
                {poData.billing_address ? poData.billing_address : "N/A"}
              </Text>
            </View>
          </View>

          {/* Special Instructions */}
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid #afafaf",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "rgb(188, 204, 204)",
                  fontSize: "8px",
                  borderBottom: "1px solid #afafaf",
                  padding: "5px",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                }}
              >
                <Text style={{ marginLeft: "40%" }}>Special Instructions</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "rgb(188, 204, 204)",
                  fontSize: "8px",
                  padding: "5px",
                  borderBottom: "1px solid #afafaf",
                }}
              >
                <Text>General</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  fontSize: "8px",
                  padding: "5px",
                  borderBottom: "1px solid #afafaf",
                }}
              >
                <Text style={styles.textLine}>
                  This purchase order is in &nbsp;
                  <Text style={styles.textLineBold}>
                    {
                      poData?.item_details[0]?.budgetitem?.budgetquantitydetails
                        ?.actual_currency?.name
                    }
                  </Text>
                </Text>
                <Text style={styles.textLine}>
                  Freight/Delivery Terms:&nbsp;
                  <Text style={styles.textLineBold}>
                    {/* CIF - COST INSURANCE & FREIGHT. */}
                    {poData?.incoterm?.name}&nbsp; {poData?.origin_type_name}
                    {/* {countryName} */}
                  </Text>
                </Text>
                <Text style={styles.textLine}>
                  Original invoices for any advance payments are to be submitted
                  immediately, matching the line items & conditions of
                  this&nbsp;
                  <Text style={styles.textLineBold}>PO</Text>.
                </Text>
                {(calculateAdvancePaymentPercentage(
                  poData.PurchaseOrderPaymentTermDetails
                ) != 0 ||
                  calculateProgressPaymentPercentage(
                    poData.PurchaseOrderPaymentTermDetails
                  ) != 0 ||
                  calculateFinalPaymentPercentage(
                    poData.PurchaseOrderPaymentTermDetails
                  ) != 0) && (
                  <Text
                    style={{
                      fontSize: "8px",
                      textDecoration: "underline",
                      marginBottom: "3px",
                      marginTop: "3px",
                    }}
                  >
                    Payment Terms:
                  </Text>
                )}
                {calculateAdvancePaymentPercentage(
                  poData.PurchaseOrderPaymentTermDetails
                ) != 0 && (
                  <Text style={styles.textLine}>
                    ADVANCE PAYMENT: &nbsp;
                    <Text style={styles.textLineBold}>
                      {calculateAdvancePaymentPercentage(
                        poData.PurchaseOrderPaymentTermDetails
                      ) !== 0 ||
                      calculateAdvancePaymentPercentage(
                        poData.PurchaseOrderPaymentTermDetails
                      ) !== "null"
                        ? calculateAdvancePaymentPercentage(
                            poData.PurchaseOrderPaymentTermDetails
                          )
                        : ""}
                    </Text>
                  </Text>
                )}
                {calculateProgressPaymentPercentage(
                  poData.PurchaseOrderPaymentTermDetails
                ) != 0 && (
                  <Text style={styles.textLine}>
                    PROGRESS PAYMENT:&nbsp;
                    <Text style={styles.textLineBold}>
                      {calculateProgressPaymentPercentage(
                        poData.PurchaseOrderPaymentTermDetails
                      ) !== 0 ||
                      calculateProgressPaymentPercentage(
                        poData.PurchaseOrderPaymentTermDetails
                      ) !== null
                        ? `${calculateProgressPaymentPercentage(
                            poData.PurchaseOrderPaymentTermDetails
                          )} `
                        : ""}
                    </Text>
                  </Text>
                )}

                {calculateFinalPaymentPercentage(
                  poData.PurchaseOrderPaymentTermDetails
                ) != 0 && (
                  <Text style={styles.textLine}>
                    FINAL PAYMENT:&nbsp;
                    <Text style={styles.textLineBold}>
                      {calculateFinalPaymentPercentage(
                        poData.PurchaseOrderPaymentTermDetails
                      ) !== 0 ||
                      calculateFinalPaymentPercentage(
                        poData.PurchaseOrderPaymentTermDetails
                      ) !== null
                        ? `${calculateFinalPaymentPercentage(
                            poData.PurchaseOrderPaymentTermDetails
                          )} `
                        : ""}
                    </Text>
                  </Text>
                )}

                <Text style={styles.textLine}>
                  Mode of payment:
                  <Text style={styles.textLineBold}>
                    {poData?.mode_of_payment?.name}
                  </Text>
                </Text>
                <Text style={styles.textLine}>
                  This PO is to be read together with Vendor Special Conditions.
                </Text>
                <Text style={styles.textLine}>
                  Go to&nbsp;
                  <Link
                    style={{ textDecoration: "none" }}
                    src="https://proqware.furnish-me.com/static/media/special-condition-to-the-purchase-order.pdf"
                  >
                    Special Conditions to the Purchase Order
                  </Link>
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "rgb(188, 204, 204)",
                  fontSize: "8px",
                  padding: "5px",
                  borderBottom: "1px solid #afafaf",
                }}
              >
                <Text>Scheduling - From Receipt of this PO</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  fontSize: "8px",
                  padding: "5px",
                }}
              >
                {poData.PurchaseOrderLeadTime[0].days_for_finish_sample ? (
                  <>
                    <Text style={styles.textLine}>
                      Finish Sample:&nbsp;
                      <Text style={styles.textLineBold}>
                        {/* {poData.item_details[0].quotation !== null
                        ? poData.item_details[0].quotation.lead_time.split(" ")[1]
                        : ""}{" "} */}
                        {
                          poData?.PurchaseOrderLeadTime[0]
                            ?.days_for_finish_sample
                        }
                        day(s)
                      </Text>
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                {poData.PurchaseOrderLeadTime[0].days_for_shop_drawing ? (
                  <>
                    <Text style={styles.textLine}>
                      Shop Drawing:&nbsp;
                      <Text style={styles.textLineBold}>
                        {/* {poData.item_details[0].quotation !== null
                        ? poData.item_details[0].quotation.lead_time.split(" ")[1]
                        : ""}{" "} */}
                        {
                          poData?.PurchaseOrderLeadTime[0]
                            ?.days_for_shop_drawing
                        }
                        day(s)
                      </Text>
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                {poData.PurchaseOrderLeadTime[0].days_for_wash_test_sample ? (
                  <>
                    <Text style={styles.textLine}>
                      Wash Test Sample:&nbsp;
                      <Text style={styles.textLineBold}>
                        {/* {poData.item_details[0].quotation !== null
                        ? poData.item_details[0].quotation.lead_time.split(" ")[1]
                        : ""}{" "} */}
                        {
                          poData?.PurchaseOrderLeadTime[0]
                            ?.days_for_wash_test_sample
                        }
                        day(s)
                      </Text>
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                {poData.PurchaseOrderLeadTime[0].days_for_sample_prototype ? (
                  <>
                    <Text style={styles.textLine}>
                      Sample Prototype:&nbsp;
                      <Text style={styles.textLineBold}>
                        {/* {poData.item_details[0].quotation !== null
                        ? poData.item_details[0].quotation.lead_time.split(" ")[1]
                        : ""}{" "} */}
                        {
                          poData?.PurchaseOrderLeadTime[0]
                            ?.days_for_sample_prototype
                        }
                        day(s)
                      </Text>
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                {poData.PurchaseOrderLeadTime[0].days_for_transit ? (
                  <>
                    <Text style={styles.textLine}>
                      Production Lead Time:&nbsp;
                      <Text style={styles.textLineBold}>
                        {/* {poData.item_details[0].quotation !== null
                        ? poData.item_details[0].quotation.lead_time.split(" ")[1]
                        : ""}{" "} */}
                        {poData?.PurchaseOrderLeadTime[0]?.days_for_production}
                        day(s)
                      </Text>
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                {poData.PurchaseOrderLeadTime[0].days_for_transit ? (
                  <>
                    <Text style={styles.textLine}>
                      Transit Lead Time:&nbsp;
                      <Text style={styles.textLineBold}>
                        {poData.PurchaseOrderLeadTime[0].days_for_transit}{" "}
                        day(s)
                      </Text>
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                {poData.mode_of_transportation ? (
                  <>
                    <Text style={styles.textLine}>
                      Mode of transport:&nbsp;
                      <Text style={styles.textLineBold}>
                        {poData.mode_of_transportation?.name || ""}
                      </Text>
                    </Text>
                  </>
                ) : (
                  <></>
                )}
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "rgb(188, 204, 204)",
                  fontSize: "8px",
                  padding: "5px",
                  borderBottom: "1px solid #afafaf",
                  borderTop: "1px solid #afafaf",
                }}
              >
                <Text>Documentation & Logistics</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  fontSize: "8px",
                  padding: "5px",
                }}
              >
                <Text style={styles.textLine}>
                  Vendor should provide originals of:{" "}
                  {poData.PurchaseOrderRequiredDocument.map(
                    (item) => item.required_document[0].name
                  ).join(", ")}
                </Text>
                {/* <Text style={styles.textLineBold}>{poData.Location.name}</Text> */}
                <Text style={styles.textLine}>
                  All goods and packaging to be clearly labelled with control
                  numbers and descriptions with corresponding control numbers on
                  all documentation.
                </Text>
                <Text style={styles.textLine}>
                  Any packaging charges or other surcharges not quoted for
                  cannot be charged.
                </Text>
                {/* <Text
                    style={{
                      fontSize: "6px",
                      fontWeight: "900",
                      paddingTop: "5px",
                    }}
                  >
                    DELIVERY WITHIN 10 WEEKS FROM THE DATE OF ORDER CONFIRMATION
                    AND ADVANCE PAYMENT.
                  </Text> */}
                {/* <Text
                    style={{
                      fontSize: "6px",
                      fontWeight: "900",
                      paddingTop: "5px",
                    }}
                  >
                    WIRE TRANSFER FEES TO BE COVERED BY BUYER.
                  </Text> */}
                {poData.notes !== "" ? (
                  <>
                    <Text
                      style={{
                        fontSize: "8px",
                        fontWeight: "900",
                        // paddingTop: "5px",
                      }}
                    >
                      {/* PORT OF DESTINATION: JEDDAH */}

                      {`*${poData.notes}*`}
                    </Text>
                  </>
                ) : (
                  <></>
                )}
              </View>
              {poData.revision_notes != "" && (
                <View style={{ width: "100%" }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      backgroundColor: "rgb(188, 204, 204)",
                      fontSize: "8px",
                      padding: "5px",
                      borderBottom: "1px solid #afafaf",
                      borderTop: "1px solid #afafaf",
                    }}
                  >
                    <Text>Reasons for revision</Text>
                  </View>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      fontSize: "8px",
                      padding: "5px",
                    }}
                  >
                    <Text style={styles.textLine}>{poData.revision_notes}</Text>
                  </View>
                </View>
              )}
            </View>
          </View>

          {/* Items Table */}
          <View style={{ marginVertical: "20px" }}>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: "5px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "rgb(188, 204, 204)",
                }}
                fixed
              >
                <Text
                  style={{
                    width: "5%",
                    fontSize: "8px",
                    padding: "5px",
                    textAlign: "center",
                    borderRight: "0.5px solid #696969",
                    color: "black",
                  }}
                >
                  S.No
                </Text>

                <Text
                  style={{
                    width: "55%",
                    fontSize: "8px",
                    padding: "5px",
                    textAlign: "left",
                    borderRight: "0.5px solid #696969",
                    color: "black",
                  }}
                >
                  Items & Description
                </Text>

                <Text
                  style={{
                    width: "10%",
                    fontSize: "8px",
                    borderRight: "0.5px solid #696969",
                    padding: "5px",
                    textAlign: "right",
                    color: "black",
                  }}
                >
                  Qty
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: "8px",
                    padding: "5px",
                    textAlign: "right",
                    borderRight: "0.5px solid #696969",
                    color: "black",
                  }}
                >
                  Rate
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: "8px",
                    padding: "5px",
                    textAlign: "right",
                    color: "black",
                  }}
                >
                  Amount
                </Text>
              </View>
              {poData.item_details &&
                poData.item_details.map((ele, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                        fontSize: "8px",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        borderBottom: "1px solid #afafaf",
                        marginTop: "0px",
                      }}
                    >
                      <View
                        style={{
                          width: "5%",
                          height: "100%",
                        }}
                      >
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            marginTop: "5px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </Text>
                      </View>

                      <View
                        style={{
                          width: "55%",
                        }}
                      >
                        {/* Description section with proper text wrapping */}
                        <Text
                          style={{
                            fontSize: "8px",
                            textAlign: "left",
                            marginTop: "5px",
                            minHeight: "12px",
                          }}
                        >
                          {`Control Number : ${
                            ele.budgetitem.control_number != null
                              ? ele.budgetitem.control_number
                              : " "
                          }`}
                        </Text>
                        <Text
                          style={{
                            fontSize: "8px",
                            textAlign: "left",
                            minHeight: "12px",
                          }}
                        >
                          {`Sub-Group : ${
                            ele.budgetitem.itemsubgroup.name != null
                              ? ele.budgetitem.itemsubgroup.name
                              : " "
                          }`}
                        </Text>
                        {checkPOItemcode(poData.item_details) === true && (
                          <Text
                            style={{
                              fontSize: "8px",
                              textAlign: "left",
                              minHeight: "12px",
                            }}
                          >
                            {`Item Code : ${
                              ele.budgetitem.item_code != null
                                ? ele.budgetitem.item_code
                                : " "
                            }`}
                          </Text>
                        )}

                        <Text
                          style={{
                            fontSize: "8px",
                            textAlign: "left",
                            minHeight: "12px",
                          }}
                        >
                          {`Item Name : ${
                            ele.budgetitem.name != null
                              ? ele.budgetitem.name
                              : " "
                          }`}
                        </Text>

                        {/* Main description text that needs to break properly */}
                        <Text
                          style={{
                            width: "100%",
                            fontSize: "8px",
                            textAlign: "left",
                            marginTop: "5px",
                            lineHeight: 1.4,
                          }}
                        >
                          {`${
                            ele.budgetitem.short_description != null
                              ? ele.budgetitem.short_description + "\n"
                              : ""
                          }${
                            ele.budgetitem.description != null
                              ? ele.budgetitem.description + "\n"
                              : ""
                          }${
                            ele.budgetitem.budget_modeldetails?.size != null
                              ? ele.budgetitem.budget_modeldetails.size + "\n"
                              : ""
                          }${
                            ele.budgetitem.budget_modeldetails?.brand != null
                              ? ele.budgetitem.budget_modeldetails.brand
                              : ""
                          }`}
                        </Text>
                      </View>

                      {/* Rest of the columns */}
                      <View
                        style={{
                          width: "10%",
                          height: "100%",
                        }}
                      >
                        <Text
                          break={false}
                          style={{
                            marginTop: "5px",
                            fontSize: "8px",
                            textAlign: "right",
                          }}
                        >
                          {ele.actual_quantity}&nbsp;{ele.actual_uom_Name}
                        </Text>
                        <Text
                          style={{
                            fontSize: "8px",
                            textAlign: "right",
                          }}
                        ></Text>
                      </View>

                      <View
                        style={{
                          width: "15%",
                          height: "100%",
                        }}
                      >
                        <CurrencyFormat
                          value={
                            ele.unit_price
                              ? Number(ele.unit_price).toFixed(2)
                              : "0.00"
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <Text
                              style={{
                                marginTop: "5px",
                                fontSize: "8px",
                                textAlign: "right",
                              }}
                            >
                              {value}
                            </Text>
                          )}
                        />
                      </View>

                      <View
                        style={{
                          width: "15%",
                          height: "100%",
                        }}
                      >
                        <CurrencyFormat
                          value={
                            ele.total_price
                              ? Number(ele.total_price).toFixed(2)
                              : "0.00"
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <Text
                              style={{
                                marginTop: "5px",
                                fontSize: "8px",
                                textAlign: "right",
                              }}
                            >
                              {value}
                            </Text>
                          )}
                        />
                      </View>
                    </View>
                  );
                })}

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px", // Reduced from 8px
                  borderRadius: "5px",
                }}
              >
                <Text
                  style={{
                    width: "85%",
                    fontSize: "10px",
                    padding: "5px",
                    textAlign: "right",
                    color: "#626262",
                  }}
                >
                  Sub Total
                </Text>
                <CurrencyFormat
                  value={
                    poData.item_details &&
                    poData.item_details
                      .reduce((acc, curr) => {
                        return acc + parseFloat(curr.total_price);
                      }, 0)
                      .toFixed(2)
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => (
                    <Text
                      style={{
                        width: "15%",
                        fontSize: "10px",
                        padding: "5px",
                        textAlign: "center",
                        color: "#626262",
                      }}
                    >
                      {value}
                    </Text>
                  )}
                />
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <Text
                  style={{
                    width: "85%",
                    fontSize: "10px",
                    padding: "5px",
                    textAlign: "right",
                  }}
                >
                  Total ({currencyName})
                </Text>
                <CurrencyFormat
                  value={
                    poData.item_details &&
                    poData.item_details
                      .reduce((acc, curr) => {
                        return acc + parseFloat(curr.total_price);
                      }, 0)
                      .toFixed(2)
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => (
                    <Text
                      style={{
                        width: "15%",
                        fontSize: "10px",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      {value}
                    </Text>
                  )}
                />
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "2px",
                marginBottom: "20px", // Increased margin
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  marginBottom: "5px",
                  color: "#595959",
                }}
              >
                Notes
              </Text>
              <Text
                style={[
                  styles.textSummery,
                  { marginTop: 0, marginBottom: "10px" },
                ]}
              >
                This Purchase Order ("PO") is prepared by FURNISH Hospitality
                General Trading LLC ("Agent") on behalf of the Client, the
                details of which are set out above ("Project Name"/"Billing
                Address") and as agent only for the Client. It consists of this
                cover sheet and the attached special conditions, the content of
                which the Vendor acknowledges and agrees to by signing this PO.
                All purchases are being made solely for the account of the
                Client. The Client shall bear the sole responsibility for all
                payments. All correspondence and other business pertaining to
                acceptance of this PO and the terms set herein, including,
                without limitation, credit approval, terms, and payment shall be
                between the Vendor and the Client and the Agent assumes no
                liability therefor, and makes no representations to the Vendor
                or the Client express or implied.
              </Text>
            </View>

            {/* signatory */}
            <View>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "#000",
                  marginBottom: 10,
                }}
              >
                Signatories
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {/* CHECKED BY FURNISH HOSPITALITY */}
                <View style={{ width: "45%", marginBottom: 20 }}>
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: "bold",
                      color: "#595959",
                      marginBottom: "15px",
                    }}
                  >
                    Checked by Furnish Hospitality
                  </Text>

                  <View
                    style={{
                      flexDirection: "row",
                      rowGap: "5px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        columnGap: "20px",
                        marginRight: "5px",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          color: "#595959",
                          marginRight: 10,
                          marginBottom: "15px",
                        }}
                      >
                        Name:
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          color: "#595959",
                          marginRight: 10,
                          marginBottom: "15px",
                        }}
                      >
                        Signature:
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          color: "#595959",
                          marginRight: 10,
                        }}
                      >
                        Date:
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        columnGap: "20px",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          flex: 1,
                          borderBottomWidth: 1,
                          borderBottomColor: "#c5c5c5",
                          height: 15,
                          marginBottom: "15px",
                          paddingBottom: "2px",
                        }}
                      >
                        {username}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          flex: 1,
                          borderBottomWidth: 1,
                          borderBottomColor: "#c5c5c5",
                          height: 15,
                          marginBottom: "15px",
                        }}
                      ></Text>
                      <Text
                        style={{
                          fontSize: 8,
                          flex: 1,
                          borderBottomWidth: 1,
                          borderBottomColor: "#c5c5c5",
                          height: 15,
                          paddingBottom: "2px",
                        }}
                      >
                        {moment(poData.created_on).format("Do MMM YYYY")}{" "}
                      </Text>
                    </View>
                  </View>
                </View>

                {/* SIGNED ON BEHALF OF CLIENT */}
                <View style={{ width: "45%", marginBottom: 20 }}>
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: "bold",
                      color: "#595959",
                      marginBottom: "15px",
                    }}
                  >
                    Signed on behalf of Client
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      rowGap: "5px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        columnGap: "20px",
                        marginRight: "5px",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          color: "#595959",
                          marginRight: 10,
                          marginBottom: "15px",
                        }}
                      >
                        Name:
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          color: "#595959",
                          marginRight: 10,
                          marginBottom: "15px",
                        }}
                      >
                        Signature:
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          color: "#595959",
                          marginRight: 10,
                        }}
                      >
                        Date:
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        columnGap: "20px",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          flex: 1,
                          borderBottomWidth: 1,
                          borderBottomColor: "#c5c5c5",
                          height: 15,
                          marginBottom: "15px",
                        }}
                      >
                        {poData.purchase_order_status === "Signed By Client" &&
                          (poData.PurchaseOrderApprovals.length > 0 &&
                          poData.PurchaseOrderApprovals[0].action_taken_on !==
                            null &&
                          poData.PurchaseOrderApprovals[0].clientcontacts
                            ? poData.PurchaseOrderApprovals[0].clientcontacts
                                .name
                            : "")}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          flex: 1,
                          borderBottomWidth: 1,
                          borderBottomColor: "#c5c5c5",
                          height: 15,
                          marginBottom: "15px",
                        }}
                      ></Text>
                      <Text
                        style={{
                          fontSize: 8,
                          flex: 1,
                          borderBottomWidth: 1,
                          borderBottomColor: "#c5c5c5",
                          height: 15,
                        }}
                      >
                        {poData.purchase_order_status === "Signed By Client" &&
                          (poData.PurchaseOrderApprovals.length > 0 &&
                          poData.PurchaseOrderApprovals[0].action_taken_on !==
                            null
                            ? moment(
                                poData.PurchaseOrderApprovals[0].action_taken_on
                              ).format("Do MMM YYYY")
                            : "")}
                      </Text>
                    </View>
                  </View>
                </View>

                {/* SIGNED ON BEHALF OF VENDOR */}
                <View style={{ width: "45%", marginBottom: 20 }}>
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: "bold",
                      color: "#595959",
                      marginBottom: "15px",
                    }}
                  >
                    Signed on behalf of Vendor
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      rowGap: "5px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        columnGap: "20px",
                        marginRight: "5px",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          color: "#595959",
                          marginRight: 10,
                          marginBottom: "15px",
                        }}
                      >
                        Name:
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          color: "#595959",
                          marginRight: 10,
                          marginBottom: "15px",
                        }}
                      >
                        Signature:
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          color: "#595959",
                          marginRight: 10,
                        }}
                      >
                        Date:
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        columnGap: "20px",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          flex: 1,
                          borderBottomWidth: 1,
                          borderBottomColor: "#c5c5c5",
                          height: 15,
                          marginBottom: "15px",
                        }}
                      ></Text>
                      <Text
                        style={{
                          fontSize: 8,
                          flex: 1,
                          borderBottomWidth: 1,
                          borderBottomColor: "#c5c5c5",
                          height: 15,
                          marginBottom: "15px",
                        }}
                      ></Text>
                      <Text
                        style={{
                          fontSize: 8,
                          flex: 1,
                          borderBottomWidth: 1,
                          borderBottomColor: "#c5c5c5",
                          height: 15,
                        }}
                      ></Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            {poData.purchase_order_status === "Signed By Client" && (
              <View
                break
                style={{
                  flexDirection: "column",
                  flexWrap: "wrap",
                  border: "1px solid #afafaf",
                  marginVertical: "20px",
                  borderRadius: "5px",
                  position: "relative",
                }}
              >
                <Text style={styles.textSummeryBold0}>
                  {`Approved on behalf of ${
                    poData.PurchaseOrderApprovals.length > 0 &&
                    poData.PurchaseOrderApprovals[0].action_taken_on !== null &&
                    poData.PurchaseOrderApprovals[0].clientcontacts
                      ? poData.PurchaseOrderApprovals[0].clientcontacts
                          .client_id?.name
                      : ""
                  }`}
                </Text>
                <Text style={styles.textSummeryBold1}>
                  *** Please quote the above order number on all delivery notes
                  and invoices pertaining to this Purchase Order ***
                </Text>
                <Text style={styles.textSummeryBold}>
                  This is a system-generated Purchase Order, hence does not
                  require any signature.
                </Text>
              </View>
            )}
          </View>

          {/* supplier Inials signature */}
          <Text style={styles.poNumbers} fixed>
            {`Purchase order #: ${
              poData.purchase_order_serial_number
                ? poData.purchase_order_serial_number
                : "N/A"
            }`}
          </Text>

          <Text
            style={styles.pageNumbers}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
          {/* <View
            fixed
            style={{
              position: "absolute",
              right: 0,
              bottom: "5",
              marginLeft: "25px",
              marginRight: "25px",
              paddingTop: "20px",
              marginTop: "10px",
            }}
          >
            <Text
              style={{
                fontSize: "8px",
                textAlign: "right",
                borderTop: "1px solid  #696969",
                marginTop: "5px",
                paddingHorizontal: "10px",
                paddingTop: "2px",
              }}
            >
              Supplier Initials
            </Text>
          </View> */}
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PurchaseOrderReport;
