/* eslint-disable no-unused-vars */
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import DraftsIcon from "@mui/icons-material/Drafts";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import faker from "faker";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Helmet } from "react-helmet";
import { AiOutlineProject } from "react-icons/ai";
import { FaProjectDiagram } from "react-icons/fa";
import Moment from "react-moment";
import "rsuite/dist/rsuite.min.css";
import {
  GetAllProjectStatusByVendor,
  GetAllPurchaseOrderByvendor,
  GetAllRFQByVendor,
  GetBudgetScopeReport,
  GetClientReport,
  GetPaymentReport,
  GetProjectStatusReport,
  GetUserNameByAuthId,
  GetVendorByEmail,
  GetVendorById,
  GetVendorReport,
} from "../../axios";
import { DashboardCard, StatusChip, VendorProjectCard } from "../../components";
import VendorDashboardCard from "../../components/common/VendorDashboardCard";
import ClientDashboardCard from "../../components/common/ClientDashboardCard";
import LoaderComponent from "../../components/common/Loader";
import { colors } from "../../configs";
import "../../styles/Dashboard.css";
import strings from "../../translations";
import ChartHorizontal from "./ChartHorizontal";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend
);

export const options = {
  responsive: true,
  tension: 1,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart Heading",
    },
  },
};

export const Baroptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

export const Bardata = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "rgba(255, 99, 132)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "rgba(53, 162, 235)",
    },
  ],
};

const Dashboard = () => {
  const dateToFormat = new Date();
  const welcomeMessage = strings["Dashboard.welcomeMessage"],
    welcomeMessageEnd = strings["Dashboard.welcomeMessageEnd"],
    totalProjects = strings["Dashboard.totalProject"],
    ongoingProjects = strings["Dashboard.ongoingProject"],
    completedProjects = strings["Dashboard.completedProject"],
    cancelledProjects = strings["Dashboard.cancelledProject"],
    draftProjects = strings["Dashboard.draftProjects"],
    projectScope = strings["Dashboard.projectScope"],
    totalVendors = strings["Dashboard.totalVendors"],
    totalClients = strings["Dashboard.totalClients"],
    projectSummery = strings["Dashboard.projectSummery"],
    budgetValuelabel = strings["Dashboard.budgetValue"],
    actualValuelabel = strings["Dashboard.actualValue"],
    savingsValuelabel = strings["Dashboard.savingsValue"],
    ffeLavel = strings["Dashboard.ffeLavel"],
    oseLavel = strings["Dashboard.oseLavel"],
    poLabel = strings["PurchaseOrder"],
    poNoLabel = strings["Dashboard.NosPo"],
    poRecvLabel = strings["Dashboard.NosPoRecieved"],
    poPoSignLabel = strings["Dashboard.NosPoSigned"],
    deadlineLabel = strings["Dashboard.Deadline"],
    RfqNoLabel = strings["Dashboard.RfqNoLabel"],
    DeadlineTblLabel = strings["Dashboard.DeadlineTblLabel"],
    statusLabel = strings["Dashboard.statusLabel"],
    paymentStatusLabel = strings["Dashboard.paymentStatusLabel"],
    issueDt = strings["Dashboard.issueDateLabel"],
    priceLabel = strings["Dashboard.poPriceLabel"],
    poSerialLabel = strings["Dashboard.PoSerialNo"],
    deliveryLabel = strings["Dashboard.DeliveryLabel"],
    ttlQtyLabel = strings["Dashboard.ttlQty"],
    deliverQtyLabel = strings["Dashboard.deliverQty"],
    noData = strings["Alert.notFound"];

  const [userName, setUserName] = React.useState("Furnish");
  const [projectCount, setProjectCount] = React.useState([]);
  const [vendorData, setVendorData] = React.useState([]);
  const [clientData, setAllClientData] = React.useState([]);
  const [scopeCount, setScopeCount] = React.useState([]);
  const [isLoader, setisLoader] = React.useState(false);
  const [barData, setBarData] = React.useState([]);
  const [totalPo, setTotalPo] = React.useState([]);
  const [vendorProject, setVendorProject] = React.useState([]);
  const [rfqStatus, setRfqStatus] = React.useState([]);
  const [paymentStatus, setPaymentStatus] = React.useState([]);
  const [DeliveryStatus, setDeliveryStatus] = React.useState([]);
  const [vendorId, setVendorId] = React.useState(null);
  const user = JSON.parse(localStorage.getItem("user"));

  React.useEffect(() => {
    if (user.loginType == "vendor") {
      const users = JSON.parse(localStorage.getItem("user"));
      const email_id = {
        email: users.email,
      };
      GetVendorByEmail(email_id)
        .then((res) => {
          localStorage.setItem("vendor", JSON.stringify(res.data.data.id));
          setVendorId(res.data.data.id);
        })
        .catch(() => {});
    }
  }, []);

  React.useEffect(() => {
    if (user.loginType == "user") {
      const users = JSON.parse(localStorage.getItem("user"));
      const id = users.identity_id;
      setisLoader(true);
      GetUserNameByAuthId(id)
        .then((res) => {
          setUserName(res.data.data.name);
          setisLoader(false);
        })
        .catch(() => {
          setisLoader(false);
        });
    }
  }, []);

  React.useEffect(() => {
    if (vendorId !== null) {
      setisLoader(true);
      GetVendorById(vendorId)
        .then((res) => {
          setUserName(res.data.data.name);
          setisLoader(false);
        })
        .catch(() => {
          setisLoader(false);
        });
    }
  }, [vendorId]);

  React.useEffect(() => {
    if (user.loginType == "user") {
      setisLoader(true);
      GetProjectStatusReport()
        .then((res) => {
          setisLoader(false);
          setProjectCount(res.data.data);
        })
        .catch(() => {
          setisLoader(false);
        });
    }
  }, []);

  React.useEffect(() => {
    if (user.loginType == "user") {
      setisLoader(true);
      GetPaymentReport()
        .then((res) => {
          const clientList = [];
          const budgetVal = [];
          const actVal = [];
          const saveVali = [];
          for (const val of res.data) {
            clientList.push(val.name);
            budgetVal.push(val.budget_value);
            actVal.push(Math.abs(val.actual_price));
            saveVali.push(val.leftprice);
          }
          setBarData({
            labels: clientList,
            datasets: [
              {
                label: { budgetValuelabel },
                data: budgetVal,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(99, 132, 0.5)",
              },
              {
                label: { actualValuelabel },
                data: actVal,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 235, 0.5)",
              },
              {
                label: { savingsValuelabel },
                data: saveVali,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "green",
              },
            ],
          });
          setisLoader(false);
        })
        .catch((err) => {
          setisLoader(false);
        });
    }
  }, []);

  React.useEffect(() => {
    if (user.loginType == "user") {
      setisLoader(true);
      GetBudgetScopeReport()
        .then((res) => {
          setisLoader(false);
          let tempList = [res.data.data.projectffe, res.data.data.projectose];
          setScopeCount(tempList);
        })
        .catch((err) => {
          setisLoader(false);
        });
    }
  }, []);

  React.useEffect(() => {
    if (user.loginType == "user") {
      setisLoader(true);
      GetVendorReport()
        .then((res) => {
          setisLoader(false);
          setVendorData(res.data.data);
        })
        .catch((err) => {
          setisLoader(false);
        });
    }
  }, []);

  React.useEffect(() => {
    if (user.loginType == "user") {
      setisLoader(true);
      GetClientReport()
        .then((res) => {
          const result = res.data.data.map((elem) => {
            return elem.name;
          });
          setisLoader(false);
          setAllClientData(result.length);
        })
        .catch((err) => {
          setisLoader(false);
        });
    }
  }, []);

  const Doughnutdata = {
    labels: [oseLavel, ffeLavel],
    datasets: [
      {
        label: "Doughnut Reprsesntation",
        data: scopeCount[0] === undefined ? [0, 0] : scopeCount,
        backgroundColor: ["rgb(92, 89, 198)", "rgb(0, 204, 136)"],
        borderWidth: 0,
      },
    ],
  };

  React.useEffect(() => {
    if (vendorId !== null && user.loginType == "vendor") {
      setisLoader(true);
      GetAllProjectStatusByVendor(vendorId)
        .then((res) => {
          setisLoader(false);
          setVendorProject(res.data.data);
        })
        .catch(() => {
          setisLoader(false);
        });
    }
  }, [vendorId]);

  React.useEffect(() => {
    if (vendorId !== null && user.loginType == "vendor") {
      setisLoader(true);
      GetAllPurchaseOrderByvendor(vendorId)
        .then((res) => {
          setisLoader(false);
          setTotalPo(res.data.data);
          setPaymentStatus(res.data.data.POAmount);
          setDeliveryStatus(res.data.data.DeliveryCount);
        })
        .catch(() => {
          setisLoader(false);
        });
    }
  }, [vendorId]);

  React.useEffect(() => {
    if (vendorId !== null && user.loginType == "vendor") {
      setisLoader(true);
      GetAllRFQByVendor(vendorId)
        .then((res) => {
          const result = res.data.data.map((ele) => ({
            name: ele.name,
            deadline: ele.valid_till,
            issueDate: ele.created_on,
            difference: moment(ele.valid_till).diff(moment(), "days") + 1,
          }));
          setRfqStatus(result);
          setisLoader(false);
        })
        .catch(() => {
          setisLoader(false);
        });
    }
  }, [vendorId]);

  return (
    <>
      <Helmet>
        <title>Furnish | Dashboard</title>
      </Helmet>
      {isLoader ? <LoaderComponent /> : null}
      <div className="content-wrapper p-0">
        {user.loginType == "client" ? (
          user.loginType == "vendor" ? (
            <>
              {/* <div className="row">
                            <div className="col-md-12" style={{ marginBottom: "1.5rem" }}>
                                <div className="row">
                                    <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                                        <h3 style={{ fontWeight: "bold" }}>
                                            {welcomeMessage}{" "} 
                                            <b style={{ color: colors.primary }}>{userName} </b>
                                        </h3>
                                        <h6 className="mb-0" style={{ fontWeight: "normal" }}></h6>
                                    </div>
                                </div>
                            </div>
                        </div> */}
              <div className="row">
                <div className="col-md-12 grid-margin transparent">
                  <div className="row">
                    <VendorProjectCard
                      cardtitle={totalProjects}
                      count={
                        vendorProject.projecttotal
                          ? vendorProject.projecttotal
                          : 0
                      }
                      color="#fff"
                      icon={
                        <AiOutlineProject
                          size="30px"
                          color="rgb(12, 84, 166)"
                        />
                      }
                    />
                    <VendorProjectCard
                      cardtitle={ongoingProjects}
                      count={
                        vendorProject.projectongoing
                          ? vendorProject.projectongoing
                          : 0
                      }
                      color="#fff"
                      icon={
                        <FaProjectDiagram
                          size="30px"
                          color="rgb(0, 204, 136)"
                        />
                      }
                    />
                    <VendorProjectCard
                      cardtitle={completedProjects}
                      count={
                        vendorProject.projectcompleted
                          ? vendorProject.projectongoing
                          : 0
                      }
                      color="#fff"
                      icon={
                        <AssignmentTurnedInIcon
                          sx={{ color: "#5C59C6", fontSize: 30 }}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <p
                        className="card-title mb-2 text-left"
                        style={{ color: colors.primary }}
                      >
                        {deadlineLabel}
                      </p>
                      <div className="table-responsive scroll-t">
                        <table className="table table-borderless">
                          <thead>
                            <tr className="text-left">
                              <th className="border-bottom pb-2">
                                {RfqNoLabel}
                              </th>
                              <th className="border-bottom pb-2">{issueDt}</th>
                              <th className="border-bottom pb-2">
                                {DeadlineTblLabel}
                              </th>
                              <th className="border-bottom pb-2">
                                {statusLabel}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {rfqStatus.length > 0 ? (
                              rfqStatus.map((ele, index) => {
                                return (
                                  <tr className="text-left" key={index}>
                                    <td>{ele.name}</td>
                                    <td>
                                      {moment(ele.issueDate).format(
                                        "DD/MMM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {moment(ele.deadline).format(
                                        "DD/MMM/YYYY"
                                      )}
                                    </td>

                                    <td className="font-weight-medium">
                                      <StatusChip
                                        label={
                                          ele.difference > 0
                                            ? "Pending"
                                            : "Done"
                                        }
                                        color={
                                          ele.difference > 0
                                            ? colors.pending
                                            : colors.success
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <div className="textCenter">
                                <p>{noData}</p>
                              </div>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 stretch-card grid-margin">
                  <div className="card h-100">
                    <div className="card-body">
                      <p
                        className="card-title mb-2 text-left"
                        style={{ color: colors.primary }}
                      >
                        {poLabel}
                      </p>
                      <div className="table-responsive border-top">
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td>{poNoLabel}</td>
                              <td>
                                {totalPo.AllPurchaseOrder
                                  ? totalPo.AllPurchaseOrder
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>{poRecvLabel}</td>
                              <td>
                                {totalPo.SendTovendorPO
                                  ? totalPo.SendTovendorPO
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>{poPoSignLabel}</td>
                              <td>
                                {totalPo.SignByvendorPO
                                  ? totalPo.SignByvendorPO
                                  : 0}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <p
                        className="card-title mb-2 text-left"
                        style={{ color: colors.primary }}
                      >
                        {paymentStatusLabel}
                      </p>
                      <div className="table-responsive scroll-t">
                        <table className="table table-borderless">
                          <thead>
                            <tr className="text-left">
                              <th className="border-bottom pb-2">
                                {poSerialLabel}
                              </th>
                              <th className="border-bottom pb-2">{issueDt}</th>
                              <th className="border-bottom pb-2">
                                {priceLabel}
                              </th>
                              <th className="border-bottom pb-2">
                                {statusLabel}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {paymentStatus.length > 0 ? (
                              paymentStatus.map((ele, index) => {
                                return (
                                  <tr className="text-left" key={index}>
                                    <td>{ele.purchase_order_seril_number}</td>
                                    <td>
                                      {moment(
                                        ele.purchase_order_created_on
                                      ).format("DD/MMM/YYYY")}
                                    </td>
                                    <td>
                                      AED {ele.total_purchase_order_price}
                                    </td>
                                    <td className="font-weight-medium">
                                      <StatusChip
                                        label={
                                          ele.left_invoice_price > 0
                                            ? "Inprogress"
                                            : "Completed"
                                        }
                                        color={
                                          ele.left_invoice_price > 0
                                            ? colors.pending
                                            : colors.success
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <div className="textCenter">
                                <p>{noData}</p>
                              </div>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <p
                        className="card-title mb-2 text-left"
                        style={{ color: colors.primary }}
                      >
                        {deliveryLabel}
                      </p>
                      <div className="table-responsive scroll-t">
                        <table className="table table-borderless">
                          <thead>
                            <tr className="text-left">
                              <th className="border-bottom pb-2">
                                {poSerialLabel}
                              </th>
                              <th className="border-bottom pb-2">
                                {ttlQtyLabel}
                              </th>
                              <th className="border-bottom pb-2">
                                {deliverQtyLabel}
                              </th>
                              <th className="border-bottom pb-2">
                                {statusLabel}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {DeliveryStatus.length > 0 ? (
                              DeliveryStatus.map((ele, index) => {
                                return (
                                  <tr className="text-left" key={index}>
                                    <td>{ele.purchase_order_seril_number}</td>
                                    <td>{ele.purchaseorderquantity}</td>
                                    <td>{ele.totaldelivered}</td>
                                    <td className="font-weight-medium">
                                      <StatusChip
                                        label={
                                          ele.totalremaining > 0
                                            ? "Pending"
                                            : "Delivered"
                                        }
                                        color={
                                          ele.totalremaining > 0
                                            ? colors.pending
                                            : colors.success
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <div className="textCenter">
                                <p>{noData}</p>
                              </div>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-md-12" style={{ marginBottom: "1.5rem" }}>
                  {/* <div className="row">
                    <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                      <h3 style={{ fontWeight: "bold" }}>
                        {welcomeMessage}{" "}
                        <b style={{ color: colors.primary }}>{userName} </b>
                      </h3>
                      <h6
                        className="mb-0"
                        style={{ fontWeight: "normal" }}
                      ></h6>
                    </div>
                  </div> */}
                </div>
              </div>
            </>
          )
        ) : (
          <>
            {/* admin dashboard */}
            <div className="row mt-4">
              <div className="col-md-8 grid-margin transparent">
                <div className="row">
                  <DashboardCard
                    cardtitle={totalProjects}
                    count={projectCount.projecttotal}
                    color="#fff"
                    url={"/allProjects"}
                    icon={
                      <AiOutlineProject size="30px" color="rgb(12, 84, 166)" />
                    }
                  />
                  <DashboardCard
                    cardtitle={ongoingProjects}
                    count={projectCount.projectongoing}
                    color="#fff"
                    url={"/ongoingproject"}
                    icon={
                      <FaProjectDiagram size="30px" color="rgb(0, 204, 136)" />
                    }
                  />
                  <DashboardCard
                    cardtitle={completedProjects}
                    count={projectCount.projectcompleted}
                    color="#fff"
                    url={"/completedproject"}
                    icon={
                      <AssignmentTurnedInIcon
                        sx={{ color: "#5C59C6", fontSize: 30 }}
                      />
                    }
                  />
                  <DashboardCard
                    cardtitle={cancelledProjects}
                    count={projectCount.projectcancelled}
                    color="#fff"
                    url={"/cancelledproject"}
                    icon={
                      <CancelPresentationIcon
                        size="30px"
                        sx={{ color: "red", fontSize: 30 }}
                      />
                    }
                  />
                  <DashboardCard
                    cardtitle={draftProjects}
                    count={projectCount.projectdraft}
                    color="#fff"
                    url={"/draftproject"}
                    icon={
                      <DraftsIcon sx={{ color: "#54DBF4", fontSize: 30 }} />
                    }
                  />
                </div>
                <div className="card vendor-chart mt-4">
                  <div className="card-body" style={{ overflow: "scroll" }}>
                    <p
                      className="card-title mb-3"
                      style={{ color: colors.primary }}
                    >
                      {projectSummery}
                    </p>
                    <div>
                      <ChartHorizontal barData={barData} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin transparent">
                <div className="right-side-div mb-4">
                  <VendorDashboardCard
                    cardtitle={totalVendors}
                    count={
                      vendorData.vendortotal ? vendorData.vendortotal : "NA"
                    }
                    color="#fff"
                    url={"master-configuration/vendor"}
                    icon={<DraftsIcon />}
                    className="right-Side"
                  />
                </div>
                <div className="right-side-div">
                  <ClientDashboardCard
                    cardtitle={totalClients}
                    count={clientData ? clientData : "NA"}
                    color="#fff"
                    url={"master-configuration/searchclient"}
                    icon={<DraftsIcon />}
                  />
                </div>
                <div className="card vendor-chart mt-4">
                  <div className="card-body">
                    <p
                      className="card-title mb-3"
                      style={{ color: colors.primary }}
                    >
                      {projectScope}
                    </p>
                    <div className="wrapper w-50">
                      <Doughnut data={Doughnutdata} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
