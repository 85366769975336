import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  header: {
    textAlign: "center",
    marginBottom: 20,
    fontSize: 14,
    fontWeight: "900",
    textDecoration: "underline",
    color:"#595959"
  },
  section: {
    marginBottom: 10,
    paddingRight: 15,
  },
  heading: {
    fontSize: 10,
    fontWeight: "900",
    color:"#595959"
  },
  subheading: {
    fontSize: 10,
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 5,
  },
  listItem: {
    marginBottom: 5,
  },
  numberedItem: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 2,
  },
  number: {
    fontSize: 10,
    width: 25,
    color:"#595959"
  },
  text: {
    fontSize: 10,
    textAlign: "justify",
    color:"#595959"
  },
  indent: {
    marginLeft: 10,
  },
});

// Create Document Component
export const TermsAndCondition = () => (
  <>
    <View style={{}}>
      <Text style={styles.header}>
        Special Conditions to the Purchase Order
      </Text>
      <Text style={{ fontSize: "10px", marginBottom: "10px" , textAlign: "justify", color:"#595959"}}>
        These special conditions (“Special Conditions”) shall be an integral
        part of the Purchase Order and the terms and conditions set out in the
        cover sheet of the Purchase Order (“Purchase Order Cover Sheet”) shall
        be read and construed in conjunction with these Special Conditions. In
        the event of any conflict between the Purchase Order Cover Sheet and
        these Special Conditions, the terms of these Special Conditions shall
        prevail.
      </Text>

      {/* Section 1: Definitions */}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>1</Text>
          <View>
            <Text style={styles.heading}>Definitions</Text>
            <Text style={styles.text}>
              Capitalised terms in these Special Conditions shall have the same
              meaning as assigned to them in the Purchase Order Cover Sheet. In
              addition, the following words and expressions shall, unless the
              context requires otherwise, have the following meanings:
            </Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>1.1</Text>
          <Text style={styles.text}>
            CLIENT means the individual or entity, the details of which are set
            out under heading "Bill-To" in the Purchase Order Cover Sheet.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>1.2</Text>
          <Text style={styles.text}>
            DELIVERY ADDRESS means the place to which the Works are to be
            delivered as specified under heading "Ship-To" in the Purchase Order
            Cover Sheet.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>1.3</Text>
          <Text style={styles.text}>
            DESIGNER means the interior designer or interior design firm
            appointed by Furnish or the Client from time to time, the identity
            of which shall be notified to the Vendor.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>1.4</Text>
          <Text style={styles.text}>
            FURNISH shall mean Furnish Hospitality General Trading LLC and shall
            be deemed to include all duly authorized representatives thereof.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>1.5</Text>
          <Text style={styles.text}>
            PURCHASE ORDER PRICE shall mean the total price for the Works set
            out under heading “Total for this Order” in the Purchase Order Cover
            Sheet.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>1.6</Text>
          <Text style={styles.text}>
            RFQ refers to Furnish’s final request for quotation as sent to the
            Vendor.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>1.7</Text>
          <Text style={styles.text}>
            SCOPE OF WORKS shall mean the Scope of Works as detailed in the
            Designers’ specifications, RFQ, drawings and/or material samples
            and/or finish samples accompanying this Purchase Order.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>1.8</Text>
          <Text style={styles.text}>
            VENDOR means the individual or entity, the details of which are set
            out under heading “Vendor” in the Purchase Order Cover Sheet.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>1.9</Text>
          <Text style={styles.text}>
            WORKS means the goods and materials identified in the Purchase Order
            Cover Sheet and as may be more particularly described in the Scope
            of Works.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>1.10</Text>
          <Text style={styles.text}>
            WORKS COMPLETION DATE means the date(s) set out in the Purchase
            Order Cover Sheet (under heading “Order Complete By”) for delivery
            of the Works (or part thereof).
          </Text>
        </View>
      </View>

      {/* Section 2: Submittals */}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>2</Text>
          <View>
            <Text style={styles.heading}>Submittals</Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>2.1</Text>
          <Text style={styles.text}>
            At the request of Furnish, the Vendor shall submit to Furnish all
            shop drawings for all generic specifications and substitutions from
            proprietary specifications for goods, fabrics and upholstered
            pieces.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>2.2</Text>
          <Text style={styles.text}>
            All submittals shall be made at the expense of the Vendor and shall
            be sent to the place and by the time reasonably designated by
            Furnish.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>2.3</Text>
          <Text style={styles.text}>
            If requested by Furnish, the Vendor shall also submit to Furnish the
            following:
          </Text>
        </View>

        <View style={styles.indent}>
          <View style={styles.numberedItem}>
            <Text style={styles.number}>(a)</Text>
            <Text style={styles.text}>
              Samples for all items of proprietary or generic specifications.
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(b)</Text>
            <Text style={styles.text}>
              All custom designs of fabrics, carpets and wall coverings
              specified as proprietary or generic specifications.
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(c)</Text>
            <Text style={styles.text}>
              All dye lots for fabrics, carpets, and wall-coverings specified as
              proprietary or generic specifications.
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(d)</Text>
            <Text style={styles.text}>all materials and finishes; and</Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(e)</Text>
            <Text style={styles.text}>
              proof of compliance of all items of the Works with all applicable
              codes (including but not limited to fire, electrical and life and
              safety codes), laws, regulations and standards and provide
              certificate(s) evidencing such.
            </Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>2.4</Text>
          <Text style={styles.text}>
            All shop drawings and other data or information submitted in
            accordance with this Clause 2 must be approved in writing by the
            Designer, Furnish and/or the Client before the Vendor proceeds under
            this Purchase Order. Any approval of the submittals, receipt of
            Works or payment made in respect of Works made pursuant to this
            Purchase Order shall not constitute approval of the Works and shall
            not limit or impair Furnish’s and/or the Client’s right of
            inspection or any other rights or remedies to which Furnish and/or
            the Client may be entitled or relieve the Vendor of any of its
            obligations and warranties hereunder. If requested, the Vendor shall
            maintain one (1) set of drawings, on which the Vendor shall record
            as-built information, and shall provide copies of the as-built
            drawings to Furnish and/or the Client.
          </Text>
        </View>
      </View>

      {/* section 3: Customer’s Own Material */}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>3</Text>
          <View>
            <Text style={styles.heading}>Customer’s Own Material</Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>3.1</Text>
          <Text style={styles.text}>
            Wherever the Vendor is required to provide certain material demand
            in order to fulfil its obligations as per this Purchase Order
            (customer’s own material "COM"), such as fabric, leather to
            upholster merchandise and/or to fabricate curtains, the Vendor shall
            be responsible for the accuracy of its COM calculations and shall be
            deemed to have satisfied itself as to the sufficiency of the
            calculation to cover its relevant obligations under this Purchase
            Order. If additional COM is required, neither Furnish nor the Client
            shall be liable for this and the Vendor shall purchase such
            additional COM at its own cost.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>3.2</Text>
          <Text style={styles.text}>
            The Vendor shall be responsible for inspecting the COM upon receipt
            and shall immediately inform the supplier of any unsuitable COM and
            Furnish if the COM is not suitable for its intended use before using
            it. The Vendor shall be liable to Furnish and the Client for any
            loss or damage arising from Vendor’s failure to comply herewith.
          </Text>
        </View>
      </View>

      {/* Section 4: Shipping*/}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>4</Text>
          <View>
            <Text style={styles.heading}>Shipping</Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>4.1</Text>
          <Text style={styles.text}>
            Two (2) weeks prior to shipment of the Works (or any part thereof),
            the Vendor shall provide an advance shipping notice with the
            anticipated shipping programme to Furnish. On the date of shipment,
            the Vendor will notify Furnish of the full details of such shipment
            including:
          </Text>
        </View>

        <View style={styles.indent}>
          <View style={styles.numberedItem}>
            <Text style={styles.number}>(a)</Text>
            <Text style={styles.text}>Purchase Order number;</Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(b)</Text>
            <Text style={styles.text}>
              method of transport and date dispatched to the Delivery Address
              (as notified to the Vendor);
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(c)</Text>
            <Text style={styles.text}>
              airline and flight number or vessel name and voyage number, date
              of departure and estimated time of arrival;
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(d)</Text>
            <Text style={styles.text}>
              number of packages and total cube/weight (in cms/kgs); and
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(e)</Text>
            <Text style={styles.text}>
              airway bill number or bill of lading number
            </Text>
          </View>

          <View>
            <Text style={styles.text}>
              Provided always that if Furnish has agreed in writing that the
              Vendor shall not be responsible for shipment of the Works (or any
              part thereof), the above obligations shall not apply. However, the
              Vendor shall provide two (2) weeks prior notice to Furnish of the
              expected delivery date of the Works (or part thereof).
            </Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>4.2</Text>
          <Text style={styles.text}>
            4.2 The Vendor shall provide the following documents to Furnish upon
            request:
          </Text>
        </View>

        <View style={styles.indent}>
          <View style={styles.numberedItem}>
            <Text style={styles.number}>(a)</Text>
            <Text style={styles.text}>
              one (1) original airway bill (air freight) or one (1) original
              bill of lading (sea freight);
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(b)</Text>
            <Text style={styles.text}>
              one (1) original certificate of origin duly
              authenticated/certified by the appropriate authorities;
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(c)</Text>
            <Text style={styles.text}>
              one (1) original export shipping invoice, duly
              authenticated/certified by the appropriate authorities; and
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(d)</Text>
            <Text style={styles.text}>one (1) original packing list</Text>
          </View>

          <View>
            <Text style={styles.text}>
              Provided always that if Furnish has agreed in writing that the
              Vendor shall not be responsible for shipment of the Works (or any
              part thereof), the Vendor shall not be obliged to provide the
              documents listed in (a) above.
            </Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>4.3</Text>
          <Text style={styles.text}>
            The Vendor shall ensure that the following marks will clearly be
            shown in full on each package (stencilled on two sides and one end
            in clear characters):
          </Text>
        </View>

        <View style={styles.indent}>
          <View style={styles.numberedItem}>
            <Text style={styles.number}>(a)</Text>
            <Text style={styles.text}>consignee and destination;</Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(b)</Text>
            <Text style={styles.text}>
              safety marks and cautions such as “Fragile,” “Glass,” “This Way
              Up”, etc. (as appropriate);
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(c)</Text>
            <Text style={styles.text}>
              package number (e.g.; 1 of 3, 2 of 3, etc.) without duplication of
              package numbers; and
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(d)</Text>
            <Text style={styles.text}>
              control numbers as designated in this Purchase Order.
            </Text>
          </View>

          <View>
            <Text style={styles.text}>
              The Vendor will also specify the total gross packed weight and
              dimensions for each shipment together with the country or
              countries of origin thereof.
            </Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>4.4</Text>
          <Text style={styles.text}>
            The Vendor shall be responsible for the correct packing method
            and/or protection to safeguard each shipment and ensure that the
            packing of each shipment shall be suitable for protection of such
            items at all times. All boxes, crates or cases shall have waterproof
            liners, or overwrap sealed with waterproof adhesive to protect goods
            susceptible to damage from moisture.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>4.5</Text>
          <Text style={styles.text}>
            The terms and conditions of this Purchase Order shall be construed
            in accordance with the relevant INCOTERMS (2000 Edition) as set out
            in the Purchase Order Cover Sheet, which shall be deemed to be
            incorporated by reference into this clause 4, provided that in the
            event of any conflict or discrepancy between the INCOTERMS and this
            Purchase Order, the Purchase Order shall prevail.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>4.6</Text>
          <Text style={styles.text}>
            In all events of the Vendor being responsible for shipping the
            Works, the Vendor shall be responsible for the acts or defaults of
            all persons and entities it uses for the purpose of shipping the
            Works, as if such persons or entities were the acts or defaults of
            the Vendor.
          </Text>
        </View>
      </View>

      {/* Section 5: Payment */}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>5</Text>
          <View>
            <Text style={styles.heading}>Payment</Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>5.1</Text>
          <Text style={styles.text}>
            The Purchase Order Price shall be a fixed price which shall not,
            save in respect of Clause 6.1 below, be subject to any increase or
            uplift of any kind, unless otherwise agreed upon by the parties.
          </Text>
        </View>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>5.2</Text>
          <Text style={styles.text}>
            The Vendor shall submit all invoices in accordance with this
            Purchase Order and such invoices shall be accompanied by original
            invoices addressed to the Client in United States Dollars (US$) or
            United Arab Emirates Dirhams (Dhs) and proofs of shipment (if
            applicable).
          </Text>
        </View>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>5.3</Text>
          <Text style={styles.text}>
            Upon receipt of any invoice submitted in accordance with Clause 5.1
            above, Furnish will undertake reasonable endeavours to ensure that
            the Client pays the invoiced amount within thirty (30) days of such
            receipt, provided always that the Client shall be entitled to
            withhold payment in the event of a breach by the Vendor of its
            obligations hereunder until such breach has been remedied and/or if
            the Vendor has failed to provide waivers and releases of claim in
            respect of the Works in a form acceptable to the Client.
          </Text>
        </View>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>5.4</Text>
          <Text style={styles.text}>
            All payments will be made in United Arab Emirates Dirhams (Dhs) or
            United States Dollars (US$) as the case may be unless otherwise
            advised by the Client or Furnish. All bank charges shall be borne by
            the Vendor.
          </Text>
        </View>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>5.5</Text>
          <Text style={styles.text}>
            If the Vendor is entitled to an advance payment, the Vendor shall
            deliver to Furnish a guarantee issued by a bank for an amount
            equalling the value of the payment (as the case may be), in a form
            and issued by a bank acceptable to the Client. If upon the
            termination of this Purchase Order, the value of the Works delivered
            to the delivery address in accordance with this Purchase Order is
            less than the amount of the advance payment then the difference in
            such amounts shall immediately become due and payable by the Vendor
            to the Client.
          </Text>
        </View>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>5.6</Text>
          <Text style={styles.text}>
            The Client shall be entitled to set off against the Purchase Order
            Price any sums owed to the Client by the Vendor.
          </Text>
        </View>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>5.7</Text>
          <Text style={styles.text}>
            The Vendor shall specify on its invoices any amounts of applicable
            value added tax (or equivalent) upon request of either Furnish or
            the Client.
          </Text>
        </View>
      </View>

      {/* section 6: Promptness/Delivery  */}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>6</Text>
          <View>
            <Text style={styles.heading}>Promptness/Delivery</Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>6.1</Text>
          <Text style={styles.text}>
            All Works shall be delivered to the Delivery Address, in the
            quantities specified in the Purchase Order Cover Sheet on or before
            the Works Completion Date. The Vendor acknowledges and agrees that
            the Client shall be free to vary the quality, specification, or
            quantities of the Works, the Works Completion Date or the Delivery
            Address upon giving reasonable notice to the Vendor and the Purchase
            Order Price shall if necessary be adjusted by a fair and reasonable
            amount to reflect the change in the Vendor's costs as a result of
            such variation.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>6.2</Text>
          <Text style={styles.text}>
            Receipt of the Works by the Client and/or Furnish at the time of
            delivery shall not constitute approval of the Works but if the Works
            are discovered to be damaged at the time of delivery they may be
            rejected and shall be promptly removed at the Vendor's expense.
          </Text>
        </View>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>6.3</Text>
          <Text style={styles.text}>
            Furnish will notify the Vendor of any short delivery of the Works
            and/or non-conformance of the Works with this Purchase Order. Once
            notice has been given to the Vendor, neither Furnish nor the Client
            shall be responsible for any damage or loss to the Works occasioned
            by the Vendor failing to collect the Works promptly.
          </Text>
        </View>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>6.4</Text>
          <Text style={styles.text}>
            The Vendor shall comply with any time frames set out in the Purchase
            Order Cover Sheet. If at any time, progress of the Works has fallen
            behind (or will fall behind), the Vendor shall immediately notify
            Furnish of such circumstance.
          </Text>
        </View>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>6.5</Text>
          <Text style={styles.text}>
            If the Works are to be installed in, or fitted to, attached to or in
            any manner integrated with the work of others, the Vendor shall
            cooperate with such persons and shall coordinate the delivery and
            the performance of the Works with such persons, in accordance with
            Furnish’s reasonable instructions.
          </Text>
        </View>
      </View>

      {/* Section 7:  Substitutions/Modifications*/}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>7</Text>
          <View>
            <Text style={styles.heading}>Substitutions/Modifications</Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>7.1</Text>
          <Text style={styles.text}>
            If the Purchase Order Cover Sheet specifies a material or finish for
            an item of the Works, which in the Vendor’s reasonable opinion is
            not fit for the intended purpose and/or is incapable of satisfying
            any other requirement for such item of the Works, the Vendor shall
            propose in writing to Furnish an alternative material and/or finish
            which the Vendor deems fit for the intended purpose and/or capable
            of satisfying such requirement (as the case may be).
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>7.2</Text>
          <Text style={styles.text}>
            The Vendor shall obtain the prior written consent of the Client for
            any substitute product, production methodology, material or finish
            which it proposes to use as an equivalent to that specified in the
            Purchase Order Cover Sheet. In particular, unless such requirement
            is waived in writing by Furnish, the Vendor shall specify the effect
            of the substitute on any required guarantees and provide evidence
            that:
          </Text>
        </View>

        <View style={styles.indent}>
          <View style={styles.numberedItem}>
            <Text style={styles.number}>(a)</Text>
            <Text style={styles.text}>
              the substitute is equal in quality and serviceability to the
              specified products;{" "}
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(b)</Text>
            <Text style={styles.text}>
              the use of the substitute will not entail changes in details,
              plans, arrangement and construction of related work;
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(c)</Text>
            <Text style={styles.text}>
              the substitute is acceptable in consideration of the required
              design and artistic effect; and
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(d)</Text>
            <Text style={styles.text}>
              the use of the substitute will not result in a cost disadvantage
              to the Client.
            </Text>
          </View>
        </View>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>7.3</Text>
          <Text style={styles.text}>
            The Vendor shall also submit such drawings, specifications, samples,
            performance data and other information as may be required to assist
            Furnish in determining whether the proposed substitution is
            acceptable. No consideration will be given to incomplete submittals.
          </Text>
        </View>
      </View>

      {/* Section 8: Fees and Taxes */}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>8</Text>
          <View>
            <Text style={styles.heading}>Fees and Taxes</Text>
          </View>
        </View>

        <View>
          <Text style={styles.text}>
            If a license or other permit is required to be issued in the country
            of origin of any item of the Works or on behalf of personnel to
            perform any obligations under this Purchase Order, the Vendor shall
            be responsible for obtaining any such license or permit at its sole
            cost.
          </Text>
        </View>
      </View>

      {/* Section 9: Inspection and Acceptance */}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>9</Text>
          <View>
            <Text style={styles.heading}>Inspection and Acceptance</Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>9.1</Text>
          <Text style={styles.text}>
            Upon Furnish’s or the Client’s reasonable request, the Vendor shall
            carry out, at its own cost, all inspections and/or tests specified
            on the Purchase Order Cover Sheet and shall advise Furnish of the
            time and place of such inspection and/or tests at least ten (10)
            calendar days in advance and in accordance with any requirements
            specified in this Purchase Order. The Vendor shall promptly provide
            Furnish with a copy of all inspection/test data following such
            inspection/test.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>9.2</Text>
          <Text style={styles.text}>
            Furnish and the Client (and its representatives) shall at all times
            be entitled to:
          </Text>
        </View>

        <View style={styles.indent}>
          <View style={styles.numberedItem}>
            <Text style={styles.number}>(a)</Text>
            <Text style={styles.text}>
              witness inspections and/or tests for which the Vendor is
              responsible;
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(b)</Text>
            <Text style={styles.text}>
              inspect or test any part of the Work; and/or
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(c)</Text>
            <Text style={styles.text}>to audit any services performed.</Text>
          </View>

          <View>
            <Text style={styles.text}>
              Such witnessing, inspections or test and audit shall not in any
              way release the Vendor from its obligations or liabilities under
              this Purchase Order. If as a result of such inspection, test or
              audit, the Works are found to be defective or otherwise not in
              accordance with this Purchase Order, the Client shall be entitled
              to require further inspections, tests or audits and if this causes
              Furnish and the Client (or its representative) to incur additional
              costs, the Vendor shall be liable for such additional costs to the
              Client. Furnish and the Client (and its representative) shall be
              allowed, free of charge, reasonable access, facilities and
              assistance at the Vendor’s premises, and at the premises of its
              subcontractors for the purpose of such inspection, testing and
              audit.
            </Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>9.3</Text>
          <Text style={styles.text}>
            If Furnish and the Client have indicated the intention to inspect
            and/or test the Works prior to shipment, the Vendor shall not ship
            any part of the Works without the prior written consent of Furnish.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>9.4</Text>
          <Text style={styles.text}>
            Furnish shall be entitled to give the Vendor instructions to proceed
            with the performance of a specific part of the Scope of Works,
            including, without limitation, review of the Vendor’s designs, etc.
            without releasing the Vendor from its obligations and liabilities
            under this Purchase Order.
          </Text>
        </View>
      </View>

      {/* Section 10: Warranties */}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>10</Text>
          <View>
            <Text style={styles.heading}>Warranties</Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>10.1</Text>
          <Text style={styles.text}>
            The Vendor warrants that the Works shall be manufactured and
            delivered (and installed if applicable) in accordance with this
            Purchase Order and with due diligence, in accordance with all
            applicable laws and according to the highest standards generally
            followed by competent vendors experienced in performing work of a
            similar nature and that it has complied with all relevant laws,
            ordinances, rules, regulations and orders.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>10.2</Text>
          <Text style={styles.text}>
            The Vendor warrants that the Works in accordance with this Purchase
            Order (irrespective of any review, comment or approval (or absence
            thereof) by Furnish or the Client) shall, unless expressly agreed
            otherwise, be of the latest proven technology, of the best quality
            and workmanship, shall be free from faulty design and shall be of
            sufficient size and capacity and of proper material so as to fulfil
            in all respects such use as may be specified in the Purchase Order
            Cover Sheet or as is commonly and reasonably expected of such Works
            and shall correspond with any specifications or other description in
            the Purchase Order Cover Sheet and with any samples provided.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>10.3</Text>
          <Text style={styles.text}>
            If any defect in the Works arises at any time up to twelve (12)
            months from the date when the Works are correctly installed and
            capable of operation as intended or twenty-four (24) months from the
            Works Completion Date (whichever is later) and the Vendor is
            notified thereof, the Vendor shall, at its own cost, promptly make
            such alterations, repairs and replacements as may be necessary to
            rectify such defect and shall reimburse Furnish and/or the Client
            for any losses, damages, costs and expenses incurred in connection
            with the defect. If the Vendor fails to promptly rectify the defect
            in accordance with such written notice, Furnish and/or the Client
            may perform or cause to be performed the necessary work at the
            Vendor’s risk and cost.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>10.4</Text>
          <Text style={styles.text}>
            Without prejudice to the rights under Clause 10.3 above, if Furnish
            and/or the Client decides that the consequences of any such defect
            cannot adequately be remedied, the Client shall be entitled to:
          </Text>
        </View>

        <View style={styles.indent}>
          <View style={styles.numberedItem}>
            <Text style={styles.number}>(a)</Text>
            <Text style={styles.text}>
              {" "}
              accept the defective part of the Works with an adjustment in the
              Purchase Order Price to reflect any losses, damages, costs and
              expenses reasonably incurred by Furnish and/or the Client in
              connection with such defective part of the Works;{" "}
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(b)</Text>
            <Text style={styles.text}>
              direct the Vendor to promptly remove the defective part of the
              Works, at the Vendor’s cost, and be reimbursed by the Vendor for
              all sums paid for the defective part of the Works and any losses,
              damages, costs and expenses reasonably incurred by Furnish and/or
              the Client in connection therewith; or
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(c)</Text>
            <Text style={styles.text}>
              replace the defective part of the Works with replacements from a
              third party and be reimbursed by the Vendor for all sums paid for
              the replacements and any losses, damages, costs and expenses
              reasonably incurred by Furnish and/or the Client in connection
              with the defective part of the Works (including any additional
              costs of the replacements).
            </Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>10.5</Text>
          <Text style={styles.text}>
            In the event of any alteration, repairs and replacements of parts of
            the Works and/or reperformance of installation by the Vendor as
            aforesaid, the above warranties shall apply to such part of the
            Works for a period of twelve (12) months from the date when such
            part of the Works is correctly installed and capable of operation as
            intended.
          </Text>
        </View>
      </View>

      {/* Section 11: Failure of Performance and Warranties */}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>11</Text>
          <View>
            <Text style={styles.heading}>
              Failure of Performance and Warranties
            </Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>11.1</Text>
          <Text style={styles.text}>
            Without prejudice to its other rights and remedies, if the Vendor
            fails to deliver the Works or any part thereof on or before the
            Works Completion Date, the Vendor shall pay to the Client, by way of
            delay liquidated damages and not as penalty, an amount calculated at
            the rate of ten (10) per cent of the Purchase Order Price for the
            Works (or part thereof) for each period of fifteen (15) consecutive
            days (pro rata on a daily basis) until the Works (or part thereof)
            are delivered in accordance with this Purchase Order.
          </Text>
        </View>
      </View>

      {/* Section 12: Termination */}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>12</Text>
          <View>
            <Text style={styles.heading}>Termination</Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>12.1</Text>
          <Text style={styles.text}>
            Without prejudice to its other rights and remedies Furnish and/or
            the Client may terminate this Purchase Order if:
          </Text>
        </View>

        <View style={styles.indent}>
          <View style={styles.numberedItem}>
            <Text style={styles.number}>(a)</Text>
            <Text style={styles.text}>
              {" "}
              the Vendor fails to proceed with the Works in such a manner that
              the Vendor will not be able to deliver the Works on or before the
              Works Completion Date in accordance with the Purchase Order Cover
              Sheet;
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(b)</Text>
            <Text style={styles.text}>
              the Vendor commits any material breach of this Purchase Order;
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(c)</Text>
            <Text style={styles.text}>
              the Vendor enters into liquidation whether compulsorily or
              voluntarily (otherwise than for the purpose of a solvent
              amalgamation or reconstruction), compounds or makes any voluntary
              arrangement with its creditors, becomes subject to an
              administration order, or has an encumbrancer take possession of,
              or a receiver or administrative receiver appointed over all or any
              part of its assets or any act is done or event occurs which (under
              applicable law) has a similar effect to any of these acts or
              events;
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(d)</Text>
            <Text style={styles.text}>
              the Vendor ceases, or threatens to cease, to carry on business; or
            </Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(e)</Text>
            <Text style={styles.text}>
              the Vendor is unable to pay its debts as they fall due.
            </Text>
          </View>

          <View>
            <Text style={styles.text}>
              In any of these events or circumstances the Client may terminate
              this Purchase Order immediately except in the case of
              sub-paragraph (a) or (b) to the extent that such failure or
              material breach (as the case may be) is capable of remedy then
              such failure or breach is not remedied with fourteen (14) days of
              written request to do so.
            </Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>12.2</Text>
          <Text style={styles.text}>
            Without prejudice to Clause 12.1 above, the Client shall be entitled
            to terminate this Purchase Order, at any time for the Client's
            convenience, by giving fourteen (14) days notice to the Vendor.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>12.3</Text>
          <Text style={styles.text}>
            Upon termination of this Purchase Order the Vendor shall take
            immediate steps to bring to an end the Works in an orderly manner
            but with all reasonable speed and economy and upon request shall
            assign all subcontracts to the Client.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>12.4</Text>
          <Text style={styles.text}>
            If this Purchase Order is terminated in accordance with Clause 12.1
            above, the Vendor shall be liable to the Client for any liabilities,
            costs, losses and damages incurred by the Client including any extra
            costs of obtaining the Works from a third party arising out of or in
            connection with such termination and the circumstances giving rise
            to such termination.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>12.5</Text>
          <Text style={styles.text}>
            If this Purchase Order is terminated in accordance with Clause 12.2
            above, the Vendor shall be entitled to payment, in accordance with
            the provisions of Clause 5 above, of such instalments of the
            Purchase Order Price as are due (but unpaid) as at the date of
            termination together with a fair and reasonable proportion of that
            part of the Purchase Order Price which has not become due, such
            proportion to be commensurate with the Works performed by the Vendor
            during the period from the date of the last application for payment
            made by the Vendor in respect of those instalments of the Purchase
            Order Price that have become due under this Purchase Order to the
            date of termination. For the avoidance of doubt, the Client shall
            not be liable for any loss of profit, loss of contracts or other
            indirect losses and/or expenses.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>12.6</Text>
          <Text style={styles.text}>
            Termination of this Purchase Order shall not affect any rights
            and/or obligations accrued before the date of termination or any
            rights and/or obligations under this Purchase Order expressed or
            intended to continue in force after termination.
          </Text>
        </View>
      </View>

      {/* Section 13: Indemnity */}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>13</Text>
          <View>
            <Text style={styles.heading}>Indemnity</Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>13.1</Text>
          <Text style={styles.text}>
            The Vendor indemnifies Furnish and the Client against any losses,
            damages, costs, expenses and liabilities arising out of or in
            connection with the Vendor's performance of this Purchase Order or
            from any breach or non-observance of the terms of this Purchase
            Order.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>13.2</Text>
          <Text style={styles.text}>
            The Vendor shall, at its own cost, hold harmless and defend Furnish
            and the Client against any losses, damages, costs (including legal
            costs and fees on an indemnity basis), expenses and liabilities of
            every kind and nature incurred in connection with any claim, suit or
            proceeding (rightful or otherwise) brought against Furnish and/or
            the Client in respect of an infringement of any trade mark,
            copyright, patent or registered design or other intellectual
            property rights, in connection with their manufacture, sale or use.
            The Vendor shall pay all damages and costs awarded against Furnish
            and/or the Client. If such goods or any part thereof, or the
            intended use of goods, is held to constitute such an infringement,
            and the use of goods or part thereof is enjoined, the Vendor shall,
            at its own cost, and at its option, either obtain for the Client the
            right to continue using such goods or part thereof or replace them
            with non-infringing goods, or modify them so that they become
            non-infringing or remove them and refund to Client the Purchase
            Order Price and the transportation and installation costs thereof
            and any costs reasonably incurred in obtaining replacement
            non-infringing goods.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>13.3</Text>
          <Text style={styles.text}>
            All drawings, reports and other documents or information produced,
            prepared and furnished by the Vendor to Furnish pertaining to or in
            the course of or as a result of the Vendor’s operations in
            connection with this Purchase Order (including those provided to
            Furnish before the date of the Vendor’s selection) shall be the
            property of the Client who shall be entitled to use them for any
            purpose.
          </Text>
        </View>
      </View>

      {/* Section 14: Insurance */}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>14</Text>
          <View>
            <Text style={styles.heading}>Insurance</Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>14.1</Text>
          <Text style={styles.text}>
            The Vendor shall maintain in force (at its own cost) a product
            liability insurance policy until three (3) years from the date when
            the Works are correctly installed and capable of operation as
            intended.
          </Text>
        </View>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>14.2</Text>
          <Text style={styles.text}>The insurance policy shall:</Text>
        </View>

        <View style={styles.indent}>
          <View style={styles.numberedItem}>
            <Text style={styles.number}>(a)</Text>
            <Text style={styles.text}>be with a reputable insurer;</Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(b)</Text>
            <Text style={styles.text}>not include unreasonable excesses;</Text>
          </View>

          <View style={styles.numberedItem}>
            <Text style={styles.number}>(c)</Text>
            <Text style={styles.text}>
              have a limit on indemnity not less than US$5 million in respect of
              one claim or series of claims arising out of any event.
            </Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>14.3</Text>
          <Text style={styles.text}>
            Within ten (10) days of a request by Furnish or the Client, the
            Vendor shall provide evidence of the insurances which it is obliged
            to maintain under this Clause 14 and receipts for the payment of any
            current insurance premiums.
          </Text>
        </View>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>14.4</Text>
          <Text style={styles.text}>
            The Vendor shall comply with all conditions set out in the insurance
            policies.
          </Text>
        </View>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>14.5</Text>
          <Text style={styles.text}>
            If the Vendor fails upon request to produce to Furnish satisfactory
            evidence that the insurance cover referred to above is in force then
            Furnish or the Client may take out the insurance cover specified and
            the Vendor shall reimburse the Client for any premiums paid.
          </Text>
        </View>
      </View>

      {/* Section 15:  Assignment*/}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>15</Text>
          <View>
            <Text style={styles.heading}>Assignment</Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>15.1</Text>
          <Text style={styles.text}>
            The Vendor shall not assign or transfer the benefit or the burden of
            this Purchase Order and shall not sub-contract the whole or any part
            of its obligations hereunder without the prior written consent of
            Furnish and/or the Client.
          </Text>
        </View>
      </View>

      {/* Section 16:  Governing Law*/}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>16</Text>
          <View>
            <Text style={styles.heading}>Governing Law</Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>16.1</Text>
          <Text style={styles.text}>
            This Purchase Order shall be governed by the laws of the United Arab
            Emirates as applicable in the Emirate of Dubai.
          </Text>
        </View>
      </View>

      {/* Section 17:  Assignment*/}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>17</Text>
          <View>
            <Text style={styles.heading}>Dispute Resolution </Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>17.1</Text>
          <Text style={styles.text}>
            The parties shall seek to resolve in good faith any dispute or
            difference arising between them in respect of any matter connected
            with this Purchase Order. If the parties cannot resolve any such
            dispute or difference within fourteen (14) days, or such period as
            the parties may subsequently agree, then any party may refer the
            dispute to arbitration in accordance with Clause 17.2 below.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>17.1</Text>
          <Text style={styles.text}>
            Any dispute arising out of or in connection with this Purchase
            Order, including any question regarding its existence, validity or
            termination, shall be referred to and finally resolved by
            arbitration under the Rules of Arbitration of the International
            Chamber of Commerce which Rules shall be deemed to be incorporated
            by reference into this Clause 17. The number of arbitrators shall be
            three. The seat of arbitration shall be Dubai. The language to be
            used in arbitral proceedings shall be English.
          </Text>
        </View>
      </View>

      {/* Section 18:  Miscellaneous*/}
      <View style={styles.section}>
        <View style={styles.numberedItem}>
          <Text style={styles.number}>18</Text>
          <View>
            <Text style={styles.heading}>Miscellaneous </Text>
          </View>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>18.1</Text>
          <Text style={styles.text}>
            The parties agree to keep strictly confidential all information of a
            proprietary or confidential nature about or belonging to a party or
            to any affiliate of a party to which the other party gains or has
            access by virtue of the relationship between the parties. Except as
            disclosure may be required to obtain the advice of professionals or
            consultants, or as may be required by law or by the order of any
            government, regulatory authority, or tribunal or otherwise to comply
            with legal requirements, each party shall undertake reasonable
            efforts to ensure that such information is not disclosed to any
            other third person without the prior consent of the other parties.
            The obligations set forth in this clause shall survive any
            termination or expiration of this Purchase Order.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>18.2</Text>
          <Text style={styles.text}>
            If any provision of this Purchase Order is found under the laws of
            any jurisdiction to be invalid, illegal or unenforceable, the
            invalidity, illegality or unenforceability of that provision in that
            jurisdiction shall not in any way affect the other provisions of
            these conditions in that jurisdiction and shall not affect the
            validity, legality or enforceability of all the provisions of this
            Purchase Order in any other jurisdiction.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>18.3</Text>
          <Text style={styles.text}>
            No failure or delay by a party to exercise any right or remedy under
            this Purchase Order shall be construed or operate as a waiver of
            that right or remedy nor shall any single or partial exercise of any
            right or remedy preclude the further exercise of that right or
            remedy. No waiver by a party of any breach of this Purchase Order
            shall be considered as a waiver of a preceding or subsequent breach.
            The rights and remedies provided in this Purchase Order are
            cumulative and are not exclusive of any rights or remedies provided
            by law.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>18.4</Text>
          <Text style={styles.text}>
            Notices between the parties relating to this Purchase Order shall be
            in writing. Notices shall either be delivered personally or sent by
            courier or fax to the address stated in this Purchase Order for the
            recipient. Notices if delivered by courier shall be treated as
            received as follows: on delivery, against written receipt; and if
            sent by fax, upon receipt of the recipient of the complete text in
            legible form.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>18.5</Text>
          <Text style={styles.text}>
            This Purchase Order constitutes the entire understanding between the
            parties concerning the Works and supersede any prior understanding
            or contract between Furnish and/or the Client and the Vendor
            concerning the subject matter hereof. The Vendor acknowledges that
            this Purchase Order shall prevail over any other conditions which
            may have previously been notified by the Vendor to the Client (or
            vice versa) and any previous course of dealing between the Vendor
            and the Client.
          </Text>
        </View>

        <View style={styles.numberedItem}>
          <Text style={styles.number}>18.6</Text>
          <Text style={styles.text}>
            No variation to this Purchase Order shall be binding unless an
            authorised representative of the Client has agreed to the variation
            in writing.
          </Text>
        </View>
      </View>
    </View>
  </>
);
